import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SvgGenerateSimilarWhiteIcon from "../../../assets/IconComponents/GenerateSimilarWhiteBg";
import ConfirmationPopupOpener from "../../../components/ConfirmationPopup/ConfirmationPopup";
import PrimaryButton from "../../../components/PrimaryButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { instanceOfApiErrorWithCode } from "../../../services/Core/BaseApi";
import {
  ProductImageRequest,
  useCreateImageMutation,
} from "../../../services/CreativeApi";
import { PaymentErrorCode } from "../../../services/Error/PaymentErrorCode";
import {
  CreateVideoRequest,
  useCreateVideoMutation,
} from "../../../services/VideoApi";
import {
  toggleContentWizard,
  toggleDialog,
  toggleLoading,
} from "../../../store/appSlice";
import { setNewContent } from "../../../store/contentSlice";
import PlanEditPopupOpener from "../../PlanPage/PlanEditPopup";

function VideoControlButtons(props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);
  const [showPlanUpdateSuccessPopup, setShowPlanUpdateSuccessPopup] =
    useState(false);
  const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
  const [isUpgradePlanPopupOpen, setIsUpgradePlanPopupOpen] = useState(false);
  const userSettings = useAppSelector((state) => state.userSettings);
  const [effectiveAt, setEffectiveAt] = useState("");

  const [
    createVideo,
    {
      isLoading: isVideotCreationLoading,
      isSuccess: videoCreationSuccess,
      data: videoCreationData,
      error: createVideotError,
    },
  ] = useCreateVideoMutation();

  useEffect(() => {
    if (videoCreationSuccess && !props.isInSide) {
      console.log(`video created success`);
      dispatch(toggleContentWizard());
      navigate("/app/loopdetails/" + videoCreationData?.content_id ?? "", {
        state: {},
      });
    } else if (props.setContentCreationDataID) {
      props.setContentCreationDataID(videoCreationData?.content_id ?? "");
    }
  }, [videoCreationSuccess]);

  useEffect(() => {
    dispatch(toggleLoading(false));
    console.log("createVideotError", createVideotError);
    if (createVideotError && instanceOfApiErrorWithCode(createVideotError)) {
      const errorData = createVideotError.data;
      if (errorData.errorCode === PaymentErrorCode.CreditLimitExceeded) {
        setShowUpgradePlanDialog(true);
      } else {
        console.log(errorData.message);
      }
    }
  }, [createVideotError]);

  useEffect(() => {
    const toggle = isVideotCreationLoading;
    if (!toggle) {
      setTimeout(() => {
        toggleLoading(false);
      }, 1000);
    } else {
      dispatch(toggleLoading(toggle));
    }
  }, [isVideotCreationLoading]);

  const createLoop = (newProductId: string | undefined) => {
    var payload = {
      referred_image_id: newContent?.referenceFileId,
      prompt_text: newContent?.video_prompt,
      video_type: newContent?.video_type,
      video_length: newContent?.video_length,
      video_resolution: newContent?.video_resolution,
      count: newContent?.sliderCount,
    } as CreateVideoRequest;
    createVideo({ createVideoRequest: payload });
  };

  return (
    <>
      {showPlanUpdateSuccessPopup && (
        <ConfirmationPopupOpener
          heading={"Your Plan Has Been Updated Successfully!"}
          subHeading={
            effectiveAt == "IMMEDIATELY"
              ? "Your plan has been successfully updated. Adjusted features are now in effect."
              : "The current plan continues until the end of your billing cycle. Your new plan will start in the next billing cycle."
          }
          buttonText={"Continue"}
          onClose={() => {
            setShowPlanUpdateSuccessPopup(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      {isUpgradePlanPopupOpen && (
        <PlanEditPopupOpener
          onClosed={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
          currentPlanId={userSettings?.subscription?.plan_id}
          paymentMethod={userSettings?.payment_method}
          onPlanUpdateSucceeded={(effectiveAt?: string) => {
            setEffectiveAt(effectiveAt ?? "");
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
            setShowPlanUpdateSuccessPopup(true);
          }}
          onUpdatePaymentMethod={() => {
            setIsUpgradePlanPopupOpen(false);
            dispatch(toggleDialog(false));
          }}
        />
      )}
      {showUpgradePlanDialog && (
        <ConfirmationPopupOpener
          heading={"Need More Credits?"}
          subHeading={
            "Upgrade your plan for more credits and never hit a roadblock in your workflow.."
          }
          buttonText={"View Plans"}
          onClose={function (): void {
            setShowUpgradePlanDialog(false);
            dispatch(toggleDialog(false));
            setIsUpgradePlanPopupOpen(true);
          }}
        />
      )}
      <div
        className={`w-full h-[85px] py-[15px] ${
          props.isInSide ? "" : "shadow bg-white"
        } justify-between items-center flex shrink-0`}
      >
        <div className="w-[52px] self-stretch px-2.5 py-2 opacity-0 bg-white rounded-[5px] border border-stone-200 justify-center items-center gap-2.5 flex">
          <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
            Back
          </div>
        </div>
        <div
          className={`w-[200px] self-stretch ${
            props.isInSide ? "justify-end" : "justify-start"
          } items-center gap-2.5 flex`}
        >
          {!props.isInSide && (
            <PrimaryButton
              title={"Cancel"}
              noFill
              className="w-[72px] h-11 mr-2"
              onClick={function (): void {
                dispatch(setNewContent(undefined));
                dispatch(toggleContentWizard());
              }}
            />
          )}
          <PrimaryButton
            isDisabled={!newContent?.referenceFileId}
            icon={props.isInSide ? <SvgGenerateSimilarWhiteIcon /> : undefined}
            title={"Generate"}
            className={props.isInSide ? "w-[120px] h-9" : "w-[101px] h-11 mr-2"}
            onClick={function (): void {
              createLoop(undefined);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default VideoControlButtons;
