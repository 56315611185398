import { coreApi as api } from "./Core/BaseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    videoPrompt: build.mutation<VideoPromptApiResponse, VideoPromptApiArg>({
      query: (queryArg) => ({
        url: `/video/videoprompt`,
        method: "POST",
        body: queryArg.videoPromptRequest,
      }),
    }),
    createVideo: build.mutation<CreateVideoApiResponse, CreateVideoApiArg>({
      query: (queryArg) => ({
        url: `/video/create`,
        method: "POST",
        body: queryArg.createVideoRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type VideoPromptApiResponse = /** status 200 OK */ VideoPromptResponse;
export type VideoPromptApiArg = {
  videoPromptRequest: VideoPromptRequest;
};
export type CreateVideoApiResponse = /** status 200 OK */ VideoResponse;
export type CreateVideoApiArg = {
  createVideoRequest: CreateVideoRequest;
};
export type VideoPromptResponse = {
  prompt?: string;
};
export type VideoPromptRequest = {
  referred_image_id?: string;
  referred_image?: string;
};
export type VideoRevision = {
  revision_id?: string;
  generated_url?: string;
};
export type VideoResponse = {
  content_id?: string;
  revisions?: VideoRevision[];
};
export type CreateVideoRequest = {
  referred_image_id?: string;
  referred_image?: string;
  prompt_text?: string;
  video_type?: string;
  video_length?: number;
  video_resolution?: string;
  count: number;
};
export const { useVideoPromptMutation, useCreateVideoMutation } =
  injectedRtkApi;
