import { t } from "i18next";
import { FunctionComponent, useEffect, useState } from "react";
import SvgSupriseIcon from "../../../assets/IconComponents/SupriseIcon";
import SvgTritoneWhatsYourContentAbout from "../../../assets/IconComponents/TritoneWhatsYourContentAbout";
import ToggleButton from "../../../components/ToggleButton";
import { useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { INewContent } from "../../../models/NewContent";
import { useVideoPromptMutation } from "../../../services/VideoApi";
import { setNewContent } from "../../../store/contentSlice";

interface VideoPromptProps {
  className?: string;
  briefContent?: INewContent;
  contentUpdated?: (updatedContent: INewContent) => void;
  hideTitle?: boolean;
}

const VideoPrompt: FunctionComponent<VideoPromptProps> = (
  props: VideoPromptProps
) => {
  const dispatch = useAppDispatch();
  const newContent = useAppSelector((k) => k.content.newContent);
  const latestBrandId = useAppSelector((k) => k.persisted.lastSelectedBrandId);
  const [
    supriseMe,
    {
      data: supriseMeData,
      isLoading: randomDataLoading,
      isError: isSurpriseMeError,
    },
  ] = useVideoPromptMutation();

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [videoPrompt, setVideoPrompt] = useState<string>("");

  let interval: any;

  useEffect(() => {
    setVideoPrompt("");

    dispatch(
      setNewContent({
        ...newContent,
        video_prompt: "",
      } as INewContent)
    );
  }, []);

  function triggerSurpriseMe(trigger: boolean) {
    if (trigger) {
      clearTimeout(interval);
      setVideoPrompt("");
      supriseMe({
        videoPromptRequest: { referred_image_id: newContent?.referenceFileId },
      });
    }
  }

  useEffect(() => {
    if (supriseMeData) {
      const sentenceArray = supriseMeData.prompt?.split("");
      let currentIndex = 0;

      const typeNextCharacter = () => {
        if (sentenceArray && currentIndex < sentenceArray.length + 1) {
          let sent = sentenceArray.slice(0, currentIndex);
          setVideoPrompt(sent.join(""));
          currentIndex++;
          interval = setTimeout(typeNextCharacter, 50); // Delay between characters (ms)
        }
      };
      typeNextCharacter();
    }
  }, [supriseMeData]);

  useEffect(() => {
    if (props.briefContent) {
      if (props.contentUpdated) {
        props.contentUpdated({
          ...props.briefContent,
          video_prompt: videoPrompt,
        });
      }
    } else {
      dispatch(
        setNewContent({
          ...newContent,
          video_prompt: videoPrompt,
        } as INewContent)
      );
    }
  }, [videoPrompt]);

  useEffect(() => {
    if (props.briefContent?.video_prompt) {
      if (videoPrompt.length === 0 && props.briefContent.video_prompt) {
        setVideoPrompt(props.briefContent.video_prompt);
      }
    }
  }, [props.briefContent]);

  useEffect(() => {
    if (!props.briefContent) {
      if (
        videoPrompt.length === 0 &&
        newContent?.video_prompt &&
        newContent?.video_prompt.length > 0
      ) {
        setCollapsed(false);
        setVideoPrompt(newContent?.video_prompt ?? "");
      }
    }
  }, [newContent]);

  return (
    <div
      className={`flex lf-stretch justify-start items-start gap-3 ${
        props.className ?? `inline-flex`
      } w-full`}
    >
      {!props.hideTitle && (
        <div className="w-[240px] h-7 justify-start items-center gap-2.5 flex">
          <SvgTritoneWhatsYourContentAbout />
          <div className="text-neutral-900 text-sm font-semibold font-['Inter Tight'] leading-tight">
            What’s your content about?
          </div>
        </div>
      )}

      <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex w-full">
        {props.briefContent === undefined && (
          <div className="flex w-full items-end justify-end mb-3">
            <ToggleButton
              isChecked={!collapsed}
              handleCheckBoxChange={function (checked: boolean): void {
                setCollapsed(!checked);
                if (!checked) {
                  setVideoPrompt("");
                }
              }}
              checkedBoxClassName="box block h-6 w-[40px] rounded-full bg-rose-100 border-2 border-red"
              checkedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red transition translate-x-full"
              uncheckedBoxClassName="box block h-6 w-[40px] rounded-full bg-neutral-200 border-2 border-neutral"
              uncheckedButtonClassName="absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-neutral-400 transition"
            />
          </div>
        )}
        {!collapsed && (
          <>
            <textarea
              value={videoPrompt}
              className={`self-stretch h-[110px] p-2.5 rounded-tl-md rounded-tr-md border border-stone-200 flex-col justify-start items-start gap-2.5 flex bg-light text-BodyMedium text-black placeholder:text-BodyMedium placeholder:text-gray overflow-scroll scrollbar-hide  outline-none resize-none`}
              placeholder={
                randomDataLoading
                  ? "Typing..."
                  : "Type What's Your Video About?"
              }
              onChange={(e) => {
                if (videoPrompt !== e.target.value) {
                  setVideoPrompt(e.target.value);
                }
              }}
            />
            <div
              className={`self-stretch pl-2.5 pr-[5px] py-[5px] rounded-bl-md rounded-br-md border border-stone-200 justify-between items-center inline-flex`}
            >
              <div></div>
              <div className="justify-end items-center gap-[5px] flex">
                <button
                  className={`w-[115px] h-7 p-[5px] bg-zinc-100 rounded-[3px] justify-end items-center gap-2 inline-flex`}
                  onClick={() => {
                    triggerSurpriseMe(true);
                  }}
                >
                  <SvgSupriseIcon fill={"#ff5555"} />
                  <text className="text-neutral-900 text-[13px] font-semibold font-['Inter Tight'] leading-[18.20px]">
                    {t("surprise-me")}
                  </text>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoPrompt;
