import { useEffect, useState } from "react";
import { useAppDispatch } from "../../helpers/hooks";
import { setActivePage, toggleMenu } from "../../store/appSlice";
import ContentForYou from "../ContentWizard/ContentForYou";
import SvgForYouLightBulb from "../../assets/IconComponents/ForYouLightBulb";
import SvgCreateTextLeaf from "../../assets/IconComponents/CreateTextLeaf";
import SvgCreateBanner from "../../assets/IconComponents/CreateBanner";
import SvgCreativeAdWand from "../../assets/IconComponents/CreativeAdWand";
import ContentTextPresetsPage from "../ContentWizard/ContentTextPresetsPage";
import ContentBanner from "../ContentWizard/ContentBanner";
import ContentCreativePage from "../ContentWizard/ContentCreativePage";
import './homepage.css';


import SearchInspirations from "./SearchInspirations"
import { Tabs } from "antd";
import ContentPhotoshoot from "../ContentWizard/ContentPhotoshoot";
import { Photoshoot, VideoGif } from "../../assets/IconComponents";
import ContentVideo from "../ContentWizard/ContentVideo";



const SelectContentHome = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const activeKeyByUrl = urlParams.get('activeGenerator');

  const dispatch = useAppDispatch();
  const [activeKey, setActiveKey] = useState("0");


  useEffect(() => {
    dispatch(setActivePage("home"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(toggleMenu(true));
  }, [dispatch]);

  useEffect(() => {
    if (activeKeyByUrl?.length) {
      setActiveKey(activeKeyByUrl);
    }
  }, [activeKeyByUrl])

  return (
    <>
      <div className="flex justify-center items-center w-full  bg-white px-10 overflow-scroll scrollbar-hide">
        <div className="flex-col justify-center items-center md:h-[calc(100vh)] bg-gray-100 rounded-lg">
          <SearchInspirations />
          <Tabs
            defaultActiveKey={activeKey}
            onTabClick={setActiveKey}
            activeKey={activeKey}
            animated={true}
            items={[
              {
                key: "0",
                className: "flex",
                label: "For You",
                icon: <SvgForYouLightBulb fill={activeKey == "0" ? "#FF5555" : "#000"} />,
                children: <ContentForYou />,
              },
              {
                key: "1",
                label: "Ad Copy",
                icon: <SvgCreateTextLeaf fill={activeKey == "1" ? "#FF5555" : "#000"} />,
                children: <ContentTextPresetsPage />,
              },
              {
                key: "2",
                label: "Banner",
                icon: <SvgCreateBanner fill={activeKey == "2" ? "#FF5555" : "#000"} />,
                children: <ContentBanner />,
              },
              {
                key: "3",
                label: "Creative Ad",
                icon: <SvgCreativeAdWand fill={activeKey == "3" ? "#FF5555" : "#000"} />,
                children: <ContentCreativePage />,
              },
              {
                key: "4",
                label: "Product Photoshoot",
                icon: <Photoshoot fill={activeKey == "4" ? "#FF5555" : "#000"} />,
                children: <ContentPhotoshoot />,
              },
              {
                key: "5",
                label: "Video & GIF",
                icon: <VideoGif fill={activeKey == "5" ? "#FF5555" : "#000"} />,
                children: <ContentVideo />,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default SelectContentHome;
